import { useContext } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import { ProgressBar } from "@fluentui/react-components";

import * as bot from "../api/bot.service";
import { TeamsBotCredentialUpdateResponse } from "../types/api-types";
import Tab from "./Tab";
import Login from "../scenes/Login";
import "./Main.css";

export default function Main() {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    let teamsBotCredentials: TeamsBotCredentialUpdateResponse | null = null;
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      console.log("userInfo", userInfo);
      const { tenantId } = userInfo;
      try {
        const teamsBotCredentialResponse = await bot.getTeamsBotCredential(
          tenantId
        );
        console.log("teamsBotCredentialResponse", teamsBotCredentialResponse);
        teamsBotCredentials = teamsBotCredentialResponse.data;
        // P2P subscription saved in key 3.
      } catch (e) {
        console.log("teamsBotCredentials - error", e);
      }

      return { userInfo, teamsBotCredentials };
    }
  });

  const isCompanyRegistered =
    loading || error ? false : data!.teamsBotCredentials?.company?.id;
  const teamsUserInfo = loading || error ? null : data!.userInfo;
  const teamsBotCredentials =
    loading || error ? null : data!.teamsBotCredentials;

  return loading ? (
    <ProgressBar color="brand" thickness="medium" />
  ) : isCompanyRegistered ? (
    <Tab userInfo={teamsUserInfo!} />
  ) : (
    <Login
      userInfo={teamsUserInfo!}
      teamsBotCredentials={teamsBotCredentials!}
    />
  );
}
