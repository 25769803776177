const AccountLinkedInstructions = () => {
  const reloadApp = () => {
    window.location.reload();
  };

  return (
    <>
      <p>
        Account Linked. Please go back to your Zuzo dashboard to sync your Team
        members.
      </p>

      <div style={{ display: "flex" }}>
        <a
          className="btn-mint dashboard-links"
          href="https://app-dev.build.zuzocard.com/dashboard/create-rewards/peer-to-peer"
          target="_blank"
        >
          Go to Zuzo dashboard
        </a>
      </div>
      <div style={{ display: "flex", marginTop: 12 }}>
        <a
          className="btn-mint-outline dashboard-links"
          onClick={() => {
            reloadApp();
          }}
        >
          Reload this page
        </a>
      </div>
    </>
  );
};

export default AccountLinkedInstructions;
