import { useCallback, useContext, useEffect, useState } from "react";
import {
  Field,
  Button,
  SpinButton,
  SpinButtonProps,
  SpinButtonChangeEvent,
  SpinButtonOnChangeData,
  Spinner,
  MessageBar,
  MessageBarBody,
} from "@fluentui/react-components";
import * as bot from "../api/bot.service";
import { useId } from "@fluentui/react-components";
import { useParams } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { useData } from "@microsoft/teamsfx-react";
import { CreateVote, SubscriptionDetails } from "../types/api-types";
import { TeamsFxContext } from "./Context";
import "./GiveReward.css";

export default function GiveReward() {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { id, nomineeId, nomineeName, originalNominatorId } = useParams();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const { loading, data, error } = useData(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    let teamsSubscription: SubscriptionDetails = {
      hasSubscription: false,
      pointsBalance: 0,
      pointValue: 0,
      adminName: "",
    };
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      console.log("userInfo", userInfo);
      const { tenantId, objectId } = userInfo;
      try {
        const subscriptionResponse = await bot.getSubscriptionStatus(
          objectId,
          tenantId
        );
        console.log("subscriptionResponse", subscriptionResponse);
        const details: any = subscriptionResponse.data.data;
        // P2P subscription saved in key 3.
        teamsSubscription = {
          hasSubscription: details[3].isActive,
          ...details[3].details,
        };
        if (teamsSubscription.pointsBalance === 0) {
          setSuccessMessage(
            "It looks like you have already spent all of your available points.\nDon't worry, we will top up your points at the beginning of the new month."
          );
        }
      } catch (e) {
        teamsSubscription.hasSubscription = false;
      }
      let issuanceData: any;
      try {
        const peerToPeerIssuance = await bot.getActivityId(id!);
        issuanceData = peerToPeerIssuance.data.data;
        console.log("issuanceData", issuanceData.messageTs);
      } catch (e) {
        issuanceData = null;
      }

      return { userInfo, teamsSubscription, issuanceData };
    }
  });

  const fieldIdPoints = useId("points-default");

  const nominatorId = loading || error ? "" : data!.userInfo.objectId;
  const groupId = loading || error ? "" : data!.userInfo.tenantId;
  const messageTs = loading || error ? "" : data!.issuanceData.messageTs;
  const initialPointsBalance =
    loading || error ? 0 : data!.teamsSubscription.pointsBalance;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [pointsBalance, setPointsBalance] =
    useState<number>(initialPointsBalance);
  const [points, setPoints] = useState<number | null>(0);

  const onPointsChange: SpinButtonProps["onChange"] = useCallback(
    (_ev: SpinButtonChangeEvent, data: SpinButtonOnChangeData) => {
      console.log("onSpinButtonChange", data.value, data.displayValue);
      if (data.value !== undefined) {
        setPoints(data.value);
      } else if (data.displayValue !== undefined) {
        const newValue = parseFloat(data.displayValue);
        if (!Number.isNaN(newValue)) {
          setPoints(newValue);
        } else {
          console.error(`Cannot parse "${data.displayValue}" as a number.`);
        }
      }
    },
    [setPoints]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    const payload: CreateVote = {
      nominatorId,
      groupId,
      nomineeId: nomineeId!,
      points: points!,
      messageTs,
    };
    console.log("handleSubmit", payload);
    if (nominatorId === nomineeId) {
      setErrorMessage("You cannot nominate yourself");
      setIsLoading(false);
      return;
    }
    if (nominatorId === originalNominatorId) {
      setErrorMessage("You cannot back your own nomination");
      setIsLoading(false);
      return;
    }
    if (points! > pointsBalance) {
      setErrorMessage("You can't reward more than the points you have.");
      setIsLoading(false);
      setPoints(null);
      return;
    }
    try {
      await bot.submitRecognition(payload);
      // updating points balance
      const newPointsBalance = pointsBalance - points!;
      setPointsBalance(newPointsBalance);

      // resetting fields
      // setNomineeId(undefined);
      setPoints(null);

      setSuccessMessage(
        `Your vote has been recorded successfully! Points will be issued shortly, ` +
          `and you can expect to see your vote reflected in the recognition channel soon.`
      );
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      setErrorMessage(e.response.data.message);
      return;
    }
  };

  const closeWindow = () => {
    microsoftTeams.dialog.url.submit();
  };

  useEffect(() => {
    if (!loading) {
      setPointsBalance(initialPointsBalance);

      if (nominatorId === nomineeId) {
        setErrorMessage("You cannot nominate yourself");
        return;
      }
      if (nominatorId === originalNominatorId) {
        setErrorMessage("You cannot back your own nomination");
        return;
      }
    }
  }, [
    loading,
    initialPointsBalance,
    nominatorId,
    nomineeId,
    pointsBalance,
    setPointsBalance,
    setErrorMessage,
    setSuccessMessage,
  ]);

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner
          size="large"
          labelPosition="after"
          label="Getting your points balance"
        />
      </div>
    );
  }
  if (successMessage) {
    return (
      <div>
        <div style={{ padding: "15 30", height: "100%" }}>
          <MessageBar
            key="success"
            intent="success"
            style={{ marginBottom: 24 }}
          >
            <MessageBarBody>{successMessage}</MessageBarBody>
          </MessageBar>
          <Button className="btn-mint" onClick={closeWindow}>
            Thanks
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ padding: "15 30", height: "100%" }}>
        <p>Never let great work go unrecognised! 💸</p>
        <hr />
        <p>
          You have <strong>{pointsBalance} points</strong> available to give
          out.
          <br /> (1 Point = R1)
        </p>

        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: 12 }}>
            <label id={fieldIdPoints}>
              Please enter a point amount to reward @{nomineeName}:
            </label>
            <Field size="small" style={{ marginTop: 6 }}>
              <SpinButton
                precision={0}
                value={points}
                max={pointsBalance}
                onChange={onPointsChange}
              />
            </Field>
          </div>
          {errorMessage && (
            <MessageBar key="error" intent="error" style={{ marginBottom: 24 }}>
              <MessageBarBody>{errorMessage}</MessageBarBody>
            </MessageBar>
          )}
          <div style={{ display: "flex" }}>
            <Button type="submit" className="btn-mint" disabled={isLoading}>
              Submit
            </Button>
            {isLoading && (
              <Spinner appearance="primary" style={{ marginLeft: 12 }} />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
