import { useState } from "react";
import { AxiosError } from "axios";
import {
  Button,
  Field,
  Input,
  InputProps,
  MessageBar,
  MessageBarBody,
  Spinner,
} from "@fluentui/react-components";
import { useId } from "@fluentui/react-components";
import * as bot from "../../../api/bot.service";

interface iLoginForm {
  setHasCode: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForm: React.FC<iLoginForm> = ({ setHasCode }) => {
  const fieldIdEmail = useId("email-address");
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const onEmailChange: InputProps["onChange"] = (ev, data) => {
    ev.preventDefault();
    // Uncontrolled inputs can be notified of changes to the value
    console.log(`New value: "${data.value}"`);
    setEmailAddress(data.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    if (emailAddress!.length < 1) {
      setErrorMessage("Please enter an email address first.");
      setIsLoading(false);
      return;
    }
    const payload: any = {
      email: emailAddress,
      otpModeEnabled: true,
    };

    try {
      await bot.sendOtp(payload);
      // reset loading
      setIsLoading(false);
      setHasCode(true);
    } catch (e: any) {
      console.log("submitRecognition - error", e);
      const axiosError = e as AxiosError;
      console.log("submitRecognition - axiosError", axiosError);
      // const message = await axiosValidationHelper(axiosError, true);
      setIsLoading(false);
      setErrorMessage(e.response.data.message);
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="instruction">
        Please enter the email address that you used when you created an account
        on our dashboard .
      </p>
      <div style={{ marginBottom: 14 }}>
        <Field size="large">
          <Input
            placeholder="email@company.com"
            id={fieldIdEmail}
            onChange={onEmailChange}
          />
        </Field>
      </div>

      {errorMessage && (
        <MessageBar key="error" intent="error" style={{ marginBottom: 24 }}>
          <MessageBarBody>{errorMessage}</MessageBarBody>
        </MessageBar>
      )}
      <div style={{ display: "flex" }}>
        <Button
          type="submit"
          className="btn-mint"
          disabled={isLoading}
          style={{ width: 180 }}
        >
          Submit
        </Button>
        {isLoading && (
          <Spinner appearance="primary" style={{ marginLeft: 12 }} />
        )}
      </div>
    </form>
  );
};

export default LoginForm;
